<!--拼团管理-->
<template>
  <div class="container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="拼团管理" name="first">
        <!-- 按钮列表 -->
        <div class="btnList1 wtl_btnList" ref="btnList">
          <div class="btnList1 wtl_btnList1">
            <el-button
              v-if="hasPermiss('ptgl_pinTuanGuanLi:addPackageTeam')"
              class="itemBtn btnColor"
              @click="newCollge()"
              >新建拼团</el-button
            >
            <el-button class="itemBtn btnColor" @click="cancelbut"
              >取消拼团</el-button
            >
            <el-button
              v-if="hasPermiss('ptgl_pinTuanGuanLi:joinTeam')"
              class="itemBtn btnColor"
              @click="joinbut"
              >加入拼团
            </el-button>
            <el-button class="itemBtn btnColor" @click="removebut"
              >移除明细</el-button
            >
            <el-button
              v-if="hasPermiss('ptgl_pinTuanGuanLi:suspend')"
              class="itemBtn btnColor"
              @click="suspendbut(0)"
            >
              暂停拼团</el-button
            >
            <el-button
              v-if="hasPermiss('ptgl_pinTuanGuanLi:suspend')"
              class="itemBtn btnColor"
              @click="suspendbut(1)"
            >
              启用拼团</el-button
            >
            <el-button class="itemBtn btnColor" @click="packbut"
              >申请打包</el-button
            >
            <el-button class="itemBtn btnColor" @click="allowbut(0, '暂停')"
              >暂停打包</el-button
            >
            <el-button class="itemBtn btnColor" @click="allowbut(1, '允许')"
              >允许打包</el-button
            >
            <el-button
              v-if="hasPermiss('ptgl_pinTuanGuanLi:updatePackageTeam')"
              class="itemBtn btnColor"
              @click="modifybut"
              >修改</el-button
            >
            <el-button class="itemBtn btnColor" @click="myModel"
              >表格设置</el-button
            >
            <el-dropdown @command="handleCommand_dc">
              <el-button class="dropdownBtn">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="dc">导出</el-dropdown-item>
                <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
                <el-dropdown-item command="scmb"
                  >保存(上传)模板</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="singleinpbtn wtl_queryBoxNew"
            v-if="hasPermiss('ptgl_pinTuanGuanLi:query')"
          >
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetData(1)">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <!-- 查询条件 -->
        <el-form :model="queryData">
          <div
            ref="queryCriteria"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <multitime
                :timeType="timeType"
                :timeTypes="timeTypes"
                :startTime="startTime"
                :endTime="endTime"
                @timestatus="timestatus"
                @queryBtn_ok="queryBtn_time"
              >
              </multitime>

              <div class="singleinp">
                <div class="singleinpleft">团号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="idList"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">拼团状态:</div>
                <div class="singleinpright">
                  <el-select
                    clearable
                    class="queryInput"
                    v-model="queryData.teamStatus"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in pintuanList"
                      :key="index"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="queryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员名称:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="queryData.nickname"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    clearable
                    class="queryInput"
                    v-model="queryData.storageId"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cangkuList"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包状态:</div>
                <div class="singleinpright">
                  <el-select
                    clearable
                    class="queryInput"
                    v-model="queryData.packStatus"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in dabaoList"
                      :key="index"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">运单号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="queryData.channelNumber"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">运输方式:</div>
                <div class="singleinpright">
                  <el-select
                    clearable
                    class="queryInput"
                    v-model="queryData.channelTransportId"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(item, index) in yunshuList"
                      :key="index"
                      :label="item.transportName"
                      :value="item.transportId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- <div class="singleinp">
                <div class="singleinpleft">建团日期:</div>
                <div class="singleinpright">
                  <el-date-picker clearable class="input" v-model="queryData.packageTeamStartCreateTime" type="date"
                    placeholder="开始日期">
                  </el-date-picker>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">建团日期:</div>
                <div class="singleinpright">
                  <el-date-picker clearable class="input" v-model="queryData.packageTeamEndCreateTime" type="date"
                    placeholder="结束日期">
                  </el-date-picker>
                </div>
              </div> -->
            </div>
          </div>
        </el-form>
        <!-- 表格 -->
        <el-table
          :height="tableHeigth"
          ref="mytable"
          :data="tableData"
          border
          :header-cell-style="{ background: '#f0f0f0' }"
          :row-class-name="tableRowClassName"
          size="small"
          show-summary
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          ></el-table-column>
          <template v-for="(item, index) in myTableHeard">
            <el-table-column
              :key="index"
              align="center"
              :show-overflow-tooltip="true"
              :label="item.name"
              :prop="item.field"
              :width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <!-- 分页组件 -->
        <paging
          :pageNum="pageNum"
          :total="total"
          :sizeList="sizeList"
          :size="size"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
        <el-dialog
          :title="title1"
          :visible.sync="isDT"
          :before-close="closebut"
          @close="resetForm('collageForm')"
        >
          <div class="newForm newCollage">
            <el-form
              ref="collageForm"
              :model="collageForm"
              :rules="collageForm"
              label-width="100px"
            >
              <div class="queryItem1">
                <el-form-item label="拼团名称:" prop="teamName">
                  <el-input
                    v-model="collageForm.teamName"
                    placeholder="请输入拼团名称"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1" style="width: 50%">
                  <el-form-item label="拼团验证码:" prop="teamCode">
                    <el-input
                      v-model="collageForm.teamCode"
                      placeholder="请输入拼团验证码"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <el-button
                  type="warning"
                  size="mini"
                  style="height: 36px"
                  @click="createTeamCode"
                  >自动填写</el-button
                >
              </div>
              <div class="queryItem1">
                <el-form-item label="拼团类型:" prop="teamGroupName">
                  <el-select
                    v-model="collageForm.teamGroupName"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in teamGroupNameArr"
                      :key="item.groupId"
                      :label="item.groupName"
                      :value="item.groupId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1" @click="changeVip">
                <el-form-item label="会员号:" prop="memberId">
                  <el-input v-model="memberId" placeholder="请选择会员号">
                  </el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="仓库:" prop="storageId">
                  <el-select v-model="collageForm.storageId">
                    <el-option
                      v-for="item in cangkuList"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="warning" size="small" @click="comfirmCollage"
              >保 存</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <div>
            <el-upload
              :action="uploadUrl"
              :show-file-list="true"
              :file-list="fileList"
              :limit="1"
              :http-request="uploadSectionFile"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">上传模板</div>
            </el-upload>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
        <sorttable
          :show_sortTableHeard="show_sortTableHeard"
          :allCheck="allCheck"
          :sortName="sortName"
          :tabelHeadeTitle="tabelHeadeTitle"
          :originalTabelHeadeTitle="originalTabelHeadeTitle"
          :selectLength="selectLength"
          @cancelSortHeard="cancelSortHeard"
          @confirmOk="confirmOk"
          @checktab="checktab"
          @checkAll="checkAll"
          @closeDias="closeDias"
        ></sorttable>
      </el-tab-pane>
      <el-tab-pane label="拼团包裹明细" name="second">
        <!-- 按钮列表 -->
        <div class="btnList1" ref="btnList">
          <!-- <el-button class="itemBtn btnColor" @click="cencanApply"
            >取消拼团申请
          </el-button> -->
          <el-button class="itemBtn btnColor" @click="seebut">查看</el-button>
          <el-button class="itemBtn btnColor" @click="myModel1"
            >表格设置
          </el-button>
          <el-dropdown @command="handleCommand_dc">
            <el-button class="dropdownBtn">
              导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dc">导出</el-dropdown-item>
              <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 查询条件框 -->
        <el-form :model="detailQueryData">
          <div
            ref="queryCriteria1"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp">
                <div class="singleinpleft">团号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="queryData.courierNumber"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">拼团状态:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.expressName"
                    placeholder="请输入内容"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">团长名称:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    clearable
                    class="queryInput"
                    v-model="detailQueryData.storageId"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cangkuList"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包状态:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">运单号:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">运输方式:</div>
                <div class="singleinpright">
                  <el-input
                    clearable
                    class="input"
                    v-model="detailQueryData.memberId"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <!--  <div class="singleinp">
                               <div class="singleinpleft">拒签状态:</div>
                               <div class="singleinpright">
                                 <el-select
                                   clearable
                                   class="input"
                                   v-model="detailQueryData.isRejection"
                                   filterable
                                   placeholder="请选择"
                                 >
                                   <el-option
                                     v-for="item in rejectionStatusList"
                                     :key="item.statusVal"
                                     :label="item.showName"
                                     :value="item.statusVal"
                                   >
                                   </el-option>
                                 </el-select>
                               </div>
                             </div> -->
              <div class="singleinp">
                <div class="singleinpleft">建团日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    clearable
                    class="input"
                    v-model="detailQueryData.startTime"
                    type="date"
                    placeholder="开始日期"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">建团日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    clearable
                    class="input"
                    v-model="detailQueryData.endTime"
                    type="date"
                    placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="item_left">
              <div class="singleinpbtn">
                <el-button
                  class="seniorbut"
                  @click="queryBtn_ok"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-tooltip content="查询项恢复初始状态" placement="top">
                  <div class="condition" @click="resetData(1)">清除</div>
                </el-tooltip>
                <el-tooltip content="查询更多条件" placement="top">
                  <div class="condition" @click="more">
                    {{ MoreConditions }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </el-form>
        <el-table
          :height="tableHeigth"
          ref="mytable"
          :data="detailTD"
          border
          show-summary
          :summary-method="getSummaries1"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          >
          </el-table-column>
          <template v-for="(item, index) in myTableHeard1">
            <el-table-column
              :key="index"
              align="center"
              :show-overflow-tooltip="true"
              :label="item.name"
              :prop="item.field"
              :width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <!-- 分页组件 -->
        <paging
          :pageNum="pageStart"
          :total="pageCount"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
        <sorttable
          :show_sortTableHeard="show_sortTableHeard1"
          :allCheck="allCheck1"
          :sortName="sortName1"
          :tabelHeadeTitle="tabelHeadeTitle1"
          :originalTabelHeadeTitle="originalTabelHeadeTitle1"
          :selectLength="selectLength1"
          @cancelSortHeard="cancelSortHeard1"
          @confirmOk="confirmOk1"
          @checktab="checktab1"
          @checkAll="checkAll1"
          @closeDias="closeDias1"
        ></sorttable>
        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <div>
            <el-upload
              :action="uploadUrl"
              :show-file-list="true"
              :file-list="fileList"
              :limit="1"
              :http-request="uploadSectionFile"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">上传模板</div>
            </el-upload>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="会员管理"
      :visible.sync="isDT_user"
      width="50%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="newForm">
        <div class="formS">
          <el-form
            ref="vf"
            size="mini"
            :model="vf"
            :rules="vfr"
            label-width="80px"
          >
            <el-row>
              <el-col :span="19" style="display: flex">
                <div class="queryItem1">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input v-model="vf.memberId"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员名称:" prop="nickname">
                    <el-input v-model="vf.nickname"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="仓库:" prop="memberId">
                    <el-select
                      v-model="vf.memberId"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button type="warning" @click="cxEdit">查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tableBox">
          <el-table
            :data="ut"
            size="mini"
            max-height="400px"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="memberId"
              label="会员号"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="会员名称"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #ef7c1b"
                  size="mini"
                  @click="xz(scope.row)"
                  >选 择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="userPn"
            :total="userPc"
            :sizeList="sizeList"
            :size="userPt"
            @handleSizeChange="handleSizeChange_user"
            @handleCurrentChange="handleCurrentChange_usre"
          ></paging>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import multitime from "@/components/multiTime.vue";
export default {
  inject: ["reload"],
  components: { draggable, paging, sorttable, multitime },
  data() {
    return {
      idList: "",
      activeName: "first",
      className: "", //  导出需要
      singleData: [],
      //  ---------- 拼团管理 ------------
      queryData: {
        //  拼团管理搜索框
        invitationCodes: [], //团号数组
        className1: "",
        channelNumber: "", //运单号
        channelTransportId: "", //渠道运输方式id
        memberId: "", //会员号
        nickname: "", //会员名称
        packStatus: "", //申请打包状态
        packageTeamStartCreateTime: "", //开团开始日期
        packageTeamEndCreateTime: "", //开团结束日期
        storageId: "", //仓库id
      },
      cangkuList: [], //仓库数组
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", // 输入框绑定动态class
      tableHeigth: "50vh", //  拼团管理表格高度
      tableData: [], //  拼团管理表格数据
      tabelHeadeTitle: [
        {
          name: "团号",
          field: "invitationCode",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "团验证码",
        //   field: "invitationCode",
        //   width: "120",
        //   sort: 1,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "拼团状态",
          field: "teamStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库",
          field: "storageName",
          width: "80",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "packNumber",
          width: "180",
          sort: 16,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "运输方式",
          field: "transportName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人姓名",
          field: "consigneeName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人电话",
          field: "receivingPhone",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "收货洲/省",
          field: "provinceName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货邮政编号",
          field: "postCode",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货城市",
          field: "cityName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货详细地址",
          field: "addressee",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道名称",
          field: "channelName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道代理",
          field: "agentName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "运单号",
          field: "channelNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹重量(KG)",
          field: "sumWeight",
          width: "120",
          sort: 13,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "包裹体积(m³)",
          field: "volume",
          width: "120",
          sort: 14,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "包裹件数",
          field: "packageCount",
          width: "80",
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包件数",
          field: "sumCount",
          width: "80",
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "申请打包备注",
          field: "packComment",
          width: "180",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包时间",
          field: "packCreateTime",
          width: "180",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包完成时间",
          field: "packTime",
          width: "180",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "完成操作人",
          field: "packerUserName",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ], //  拼团管理表格动态表头--默认
      totalArr: [], //  需要合计的字段
      input: "",
      myTableHeard: "", //  拼团管理表格动态表头
      pageNum: 1, //  页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //  一页几条
      isDT: false, //  新建拼团弹出层状态
      pageType: "", //新增还是修改
      collageForm: {
        storageId: "",
        teamName: "",
        teamCode: "",
        teamGroupName: "",
        userId: "",
      }, //  新建拼团弹出层状态表单
      teamGroupNameArr: [],
      //  ---------  拼团包裹明细  ----------
      detailQueryData: {}, //  拼团包裹明细搜索表单
      detailTD: [], //  拼团包裹明细表格数据
      tabelHeadeTitle1: [
        {
          name: "快递单号",
          field: "",
          width: "180",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "packNumber",
          width: "180",
          sort: 16,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "仓库",
          field: "storageName",
          width: "80",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "团号",
          field: "invitationCode",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "收件人姓名",
          field: "consigneeName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件人电话",
          field: "receivingPhone",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "收货洲/省",
          field: "provinceName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货邮政编号",
          field: "postCode",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货城市",
          field: "cityName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收货详细地址",
          field: "addressee",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "运输方式",
          field: "transportName",
          width: "120",
          sort: 1,
          isShow: false,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道名称",
          field: "channelNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道代理",
          field: "",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "运单号",
          field: "",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹重量(KG)",
          field: "sumWeight",
          width: "0",
          sort: 13,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "包裹体积(m³)",
          field: "volume",
          width: "120",
          sort: 14,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "包裹件数",
          field: "packageCount",
          width: "80",
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },

        {
          name: "申请打包备注",
          field: "packComment",
          width: "180",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包时间",
          field: "packCreateTime",
          width: "180",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "applicantUserName",
          width: "80",
          sort: 22,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ], //  拼团包裹明细表格默认表头
      myTableHeard1: [], //  拼团包裹明细表格动态表头
      totalArr1: [], //  需要合计的字段
      pageStart: 1, //  分页配置
      pageCount: 0,
      pageTotal: 50,
      //  会员管理
      isDT_user: false,
      vf: {},
      title1: "",
      vfr: {},
      ut: [],
      userPn: 1,
      userPc: 0,
      userPt: 20,
      memberId: "",
      // ======公用组件==========
      show_sortTableHeard: false, //显示弹窗
      show_sortTableHeard1: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      selectLength: 0, //显示的长度
      selectLength1: 0, //显示的长度
      allCheck: false, //全选
      allCheck1: false, //全选
      tableData1: [],
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      originalTabelHeadeTitle: [],
      pintuanList: [],
      dabaoList: [],
      yunshuList: [],
      originalTabelHeadeTitle1: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      sortName1: "模板名", //排序名
      pageGroupName: "Collage", //页面标识
      startTime: "",
      endTime: "",
      timeType: "0",
      timeTypes: [
        {
          value: "0",
          label: "开团时间",
        },
      ],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    if (this.originalTabelHeadeTitle1.length == 0) {
      this.originalTabelHeadeTitle1 = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle1)
      );
    }
    //  -------- new ----------
    this.myGetStorageList(); //  获取仓库列表
    // -------- end -----------
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.startTime = tools.getFlexDate(-10); //10天前;
    this.endTime = tools.getCurrentDate(); //今天的时间;;
  },
  mounted() {
    this.getData();
    this.myGetTeamGroupName();
    this.getlist();
  },
  watch: {},
  methods: {
    queryBtn_time(s, e) {
      this.startTime = s || "";
      this.endTime = e || "";
    },
    timestatus(type, s, e) {
      this.timeType = type || "0";
      this.startTime = s || "";
      this.endTime = e || "";
    },
    //生成团验证码
    createTeamCode() {
      Api.createTeamCode().then((res) => {
        this.collageForm.teamCode = res.data.result.number;
      });
    },
    getlist() {
      //拼团状态
      Api.getStatusValList({
        tableAndFieldName: "package_team_v1.team_status",
      }).then((res) => {
        this.pintuanList = res.data.result || [];
      });
      //打包状态
      Api.getStatusValList({
        tableAndFieldName: "bill_pack_v1.pack_status",
      }).then((res) => {
        this.dabaoList = res.data.result || [];
      });
      //运输方式
      Api.getTransportMethodList().then((res) => {
        console.log(res.data.result.data);
        this.yunshuList = res.data.result.data || [];
      });
    },
    tableRowClassName({ row, rowIndex }) {
      //表格样式i
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    getData() {
      // 获取数据
      let nid = tools.getOrderNum(this.idList);
      console.log(nid);
      let endTime = "";
      let startTime = "";
      if (this.endTime) {
        endTime = this.endTime + " 23:59:59";
      }
      if (this.startTime) {
        startTime = this.startTime + " 00:00:00";
      }
      this.queryData.packageTeamStartCreateTime = startTime;
      this.queryData.packageTeamEndCreateTime = endTime;
      this.queryData.invitationCodes = nid;
      this.queryData.pageStart = this.pageNum;
      this.queryData.pageTotal = this.size;
      Api.packageTeamList(this.queryData).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },

    queryBtn_ok() {
      // 查询按钮
      this.pageNum = 1;
      this.getData();
    },

    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (!this.judge) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    resetData(num) {
      // 搜索框表单重置
      switch (num) {
        case 1:
          this.queryData = {
            invitationCodes: [], //团号数组
            channelNumber: "", //运单号
            channelTransportId: "", //渠道运输方式id
            memberId: "", //会员号
            nickname: "", //会员名称
            packStatus: "", //申请打包状态
            packageTeamStartCreateTime: "", //开团开始日期
            packageTeamEndCreateTime: "", //开团结束日期
            storageId: "", //仓库id
          };
          this.idList = "";
          this.startTime = "";
          this.endTime = "";
          break;
        case 2:
          break;
      }
    },
    //新增
    newCollge() {
      this.isDT = true;
      this.pageType = "add";
      this.title1 = "新建拼团";
    },
    //修改
    modifybut() {
      let singleData = this.singleData;
      if (singleData.length == 1) {
        this.title1 = "修改拼团";
        let teamId = singleData[0].packageTeamId;
        this.isDT = true;
        Api.packageTeamDetail({ packageTeamId: teamId }).then((res) => {
          console.log(res.data.result);
          this.collageForm = {
            packageTeamId: res.data.result.packageTeamId || "",
            storageId: res.data.result.storageId || "",
            teamName: res.data.result.teamName || "",
            teamCode: res.data.result.invitationCode || "",
            teamGroupName: res.data.result.teamGroupName || "",
            userId: res.data.result.userId || "",
          };
          this.memberId = res.data.result.memberId || "";
        });
      } else {
        this.$message.warning("请勾选一条数据");
      }
    },
    myGetTeamGroupName() {
      //  获取拼团类型
      Api.packageTeamGroupChooseList().then((res) => {
        if (res.data.status === "success") {
          this.teamGroupNameArr = res.data.result;
        } else {
          console.log(`${res.data.message}--获取拼团类型`);
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancelbut() {
      this.$message.warning("暂无接口!");
    },

    joinbut() {
      //  加入拼团
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/BusinessManagement/JoinManagement",
          query: {
            code: this.singleData[0].invitationCode,
            id: this.singleData[0].packageTeamId,
          },
        });
      } else {
        this.$message.warning("请选择");
      }
    },

    suspendbut(num) {
      //  启用&暂停拼团
      if (this.singleData.length === 1) {
        let msg = "暂停";
        if (num === 1) {
          msg = "启用";
        }
        let packageArr = [];
        this.singleData.forEach((item) => {
          packageArr.push(item.packageTeamId);
        });
        this.$confirm(`是否${msg}拼团?`, "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          Api.onoffPackage({
            enableType: num,
            packageTeamId: packageArr,
          }).then((res) => {
            if (res.data.status === "success") {
              this.$message.success(res.data.message);
              this.getData();
            } else {
              this.$message.error(`${res.data.message}--启用&暂停拼团`);
            }
          });
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },

    allowbut(num, title) {
      let singleData = this.singleData;
      if (singleData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.$confirm("是否" + title + "打包?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          let nidlist = new Array();
          let param = new Object();
          singleData.forEach((item) => {
            console.log(item.packId);
            nidlist.push(item.packId);
          });
          param.billPackId = nidlist;
          let sign = tools.getSign(param);
          param.sign = sign;
          if (num == 1) {
            //允许打包
            Api.allowPackage(param).then((res) => {
              console.log(res.dat);
            });
          } else {
            //暂停打包
            Api.stopPackage(param).then((res) => {
              console.log(res.dat);
            });
          }
        });
      }
    },
    //新建修改拼团弹窗
    closebut() {
      this.isDT = false;
      this.collageForm = {
        storageId: "",
        teamName: "",
        teamCode: "",
        teamGroupName: "",
        userId: "",
      };
      this.memberId = "";
    },
    comfirmCollage() {
      //  新建拼团确认
      let collageForm = this.collageForm;
      if (this.title1 == "新建拼团") {
        let parm = {
          storageId: collageForm.storageId,
          teamName: collageForm.teamName,
          teamCode: collageForm.teamCode,
          teamGroupName: collageForm.teamGroupName,
          userId: collageForm.userId,
        };
        var secretKey = tools.getSign(parm);
        parm.sign = secretKey;
        Api.addPackageTeam(parm).then((res) => {
          if (res.data.status === "success") {
            this.isDT = false;
            this.$message.success(res.data.message);
            this.getData();
            this.closebut();
          } else {
            this.$message.error(`${res.data.message}--新建拼团确认`);
          }
        });
      } else {
        let param = {
          storageId: collageForm.storageId,
          teamName: collageForm.teamName,
          teamCode: collageForm.teamCode,
          teamGroupName: collageForm.teamGroupName,
          userId: collageForm.userId,
          packageTeamId: collageForm.packageTeamId,
        };
        var sign = tools.getSign(param);
        param.sign = sign;
        Api.updatePackageTeam(param).then((res) => {
          if (res.data.status === "success") {
            this.isDT = false;
            this.$message.success(res.data.message);
            this.getData();
            this.closebut();
          }
        });
      }
    },
    handleClick() {
      //  顶部标签页切换
      if (this.activeName === "second") {
        this.pageGroupName = "pintuanguanli1";
        this.SignInRecord();
      } else {
        this.pageGroupName = "Collage";
        this.getGroupFindFieldSort();
      }
      this.singleData = [];
    },

    handleSelectionChange(e) {
      // 表格选择事件
      this.singleData = e;
    },
    //    -------------------------- 拼团包裹明细 ------------------------

    getPackageTeamDetails() {
      //  获取拼团包裹明细
      this.detailQueryData.pageStart = this.pageStart;
      this.detailQueryData.pageTotal = this.pageTotal;
      Api.packageTeamDetails(this.detailQueryData).then((res) => {
        if (res.data.status === "success") {
          this.className1 = res.data.result.className || "";
          this.detailTD = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    SignInRecord() {
      //  拼团包裹明细表头获取
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            this.myTableHeard1 = JSON.parse(arr[0].sortObject);
            this.sortName1 = arr[0].sortName;
          }
        } else {
          this.myTableHeard1 = this.tabelHeadeTitle1;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < this.myTableHeard1.length; i++) {
          if (this.myTableHeard1[i].isTotal) {
            totalArr.push(this.myTableHeard1[i].field);
          }
        }
        this.totalArr1 = totalArr;
        this.getPackageTeamDetails(); //  获取包裹明细列表
        this.$nextTick(() => {
          that.$refs.mytable.doLayout();
        });
      });
    },

    cencanApply() {},

    //  -------------- 会员管理  ----------------
    changeVip() {
      //  选择会员名称
      this.getVipList();
      this.isDT_user = true;
    },
    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.userPn;
      this.vf.pageTotal = this.userPt;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          this.ut = res.data.result.data;
          this.userPc = res.data.result.pageCount || 0;
        }
      });
    },
    xz(data) {
      //  会员表格选择事件
      this.memberId = data.memberId;
      console.log(data);
      this.collageForm.userId = data.userId;
      this.isDT_user = false;
    },
    cxEdit() {
      //  查询按钮
      this.userPn = 1;
      this.getVipList();
    },
    handleSizeChange_user(val) {
      //  分页事件
      this.userPt = val;
      this.getVipList();
    },
    handleCurrentChange_usre(val) {
      //  分页事件
      this.userPn = val;
      this.getVipList();
    },

    handleClose() {
      //  会员弹出框关闭前回调事件
      // this.resetData('vf')
    },
    //          end
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    //移除明细
    removebut() {},

    //申请打包
    packbut() {
      if (this.singleData.length < 1) {
        this.$message.warning("请选择一个团");
      } else if (this.singleData.length > 1) {
        this.$message.warning("只能选择一个团");
      } else {
        this.$router.push({
          path: "/BusinessManagement/Pickingconfirmation",
          query: {
            pageType: "apply",
            tunNum: this.singleData[0].invitationCode,
          },
        });
      }
    },
    //查看
    seebut() {
      this.$router.push("/BusinessManagement/Pickingconfirmation?pageType=");
    },

    //出库确认
    DeliveryConfirmation() {
      this.$confirm("出库数量: 10个", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message.success("出库成功");
        })
        .catch(() => {});
    },
    //取消出库
    CancelIssue() {
      this.$confirm("取消出库数量: 5个", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message.success("取消出库成功");
        })
        .catch(() => {});
    },
    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let className;
      let that = this;
      let datas = [];
      if (this.pageGroupName == "Collage") {
        datas = that.tableData;
        className = that.className;
      } else {
        console.log(that.claimedData);
        datas = that.tableData1;
        className = that.className1;
      }
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      let array = {
        customizedExportId: customizedExportId,
        className: className,
        data: JSON.stringify(datas),
        sign: signs_e,
      };
      Api.templateDataExport(array).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          if (this.pageGroupName == "Collage") {
            tools.dynamicExport(
              this.myTableHeard,
              this.className,
              this.tableData,
              "拼团管理列表"
            );
          } else {
            tools.dynamicExport(
              this.myTableHeard1,
              this.className1,
              this.detailTD,
              "拼团明细列表"
            );
          }
        }
      });
    },
    // ===============================================导出部分=结束======

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
    getStr(orderNos) {
      let arr = [];
      let arr_1 = [];
      if (orderNos.indexOf("\n") !== -1) {
        arr = orderNos.split("\n");
        //  ['123 344,dsg ewe','234 233','32,234']
        for (var i = 0; i < arr.length; i++) {
          let arr_a = [];
          if (arr[i].indexOf(",") !== -1) {
            arr_a = arr[i].split(",");
            arr_a.map((item) => {
              arr_1.push(item);
            });
          } else {
            if (arr[i]) {
              arr_1.push(arr[i]);
            }
          }
        }
        setTimeout(() => {
          for (var j = 0; j < arr_1.length; j++) {
            let arr_b = [];
            if (arr_1[j].indexOf(" ") !== -1) {
              arr_b = arr_1[j].split(" ");
              arr_b.map((item) => {
                arr_b.push(item);
              });
            } else {
              if (arr_1[j]) {
                arr_b.push(arr_1[j]);
              }
            }
          }
        }, 20);
      } else if (orderNos.indexOf(",") !== -1) {
        arr = orderNos.split(",");
        //  ['123 344','234','32 234']
        for (var b = 0; b < arr.length; b++) {
          let arr_a = [];
          if (arr[b].indexOf(" ") !== -1) {
            arr_a = arr[b].split(" ");
            arr_a.map((item) => {
              arr_1.push(item);
            });
          } else {
            if (arr[b]) {
              arr_1.push(arr[b]);
            }
          }
        }
      } else if (orderNos.indexOf(" ") !== -1) {
        arr = orderNos.split(" ");
        arr.map((item) => {
          if (item) {
            arr_1.push(item);
          }
        });
      } else {
        arr_1 = [orderNos];
      }
      let endArr = [];
      for (var q = 0; q < arr_1.length; q++) {
        if (arr_1[q].indexOf(" ") != -1) {
          let arrs = arr_1[q].split(" ");
          for (var w = 0; w < arrs.length; w++) {
            if (arrs[w]) {
              endArr.push(arrs[w]);
            }
          }
        } else {
          if (arr_1[q]) {
            endArr.push(arr_1[q]);
          }
        }
      }
      return endArr;
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //设置模板按钮(签收记录)
    myModel1() {
      let that = this;
      that.show_sortTableHeard1 = true;
      that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },

    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    //关闭弹窗
    closeDias1(e) {
      this.show_sortTableHeard1 = e;
    },

    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    // 合计
    getSummaries1(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr1.length; i++) {
          if (column.property == that.totalArr1[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    // 排序==>取消按钮(签收记录)
    cancelSortHeard1() {
      this.show_sortTableHeard1 = false;
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
    },
    // 排序==>确认按钮(签收记录)
    confirmOk1(e) {
      this.tabelHeadeTitle1 = e;
      this.myTableHeard1 = this.tabelHeadeTitle1;
      let sortObject = this.myTableHeard1;
      let signs = [
        {
          key: "sortName",
          val: this.sortName1, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName1, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard1 = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
      this.show_sortTableHeard1 = false;
    },
    // 点击某一项
    checktab1(idx, e) {
      let that = this;
      that.tabelHeadeTitle1[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    //全选
    checkAll1(e) {
      let that = this;
      this.allCheck1 = e;
      for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
        this.tabelHeadeTitle1[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
    },
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },

    //    -------------- 公用 ---------------

    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight;
        if (this.activeName === "second") {
          queryHeigth = this.$refs.queryCriteria1.offsetHeight + 30; //查询框
        }
      } else {
        queryHeigth = 50;
        if (this.activeName === "second") {
          queryHeigth = 30;
        }
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 60;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
  },
};
</script>

<style scoped lang="scss">
.newCollage {
  margin-left: 100px;

  .queryItem1 {
    width: 60%;
    margin-bottom: 10px;

    /deep/ .el-form-item,
    /deep/ .el-form-item__label,
    /deep/ .el-form-item__content {
      margin-bottom: 0;
      min-height: 30px;
      line-height: 28px;
    }

    /deep/ .el-input__inner {
      min-height: 30px;
      line-height: 28px;
    }
  }
}

.formS {
  border-bottom: 1px silver solid;
  padding-bottom: 20px;
}

.tableBox {
  width: 80%;
  padding: 20px 60px;
}
</style>
